import useRouter from './useRouter';

const usePrice = (options?: Intl.NumberFormatOptions) => {
  const { locale } = useRouter();
  const numberFormat = new Intl.NumberFormat(locale, {
    currency: 'EUR',
    style: 'currency',
    ...options,
  });
  const parts = numberFormat.formatToParts(1000.1);
  const currencySymbol = parts.find((part) => part.type === 'currency')?.value;
  const thousandSeparator = parts.find((part) => part.type === 'group')?.value;
  const decimalSeparator = parts.find((part) => part.type === 'decimal')?.value;

  const formatPrice = (price: number) => numberFormat.format(price);

  const parsePrice = (price: string) => {
    const formattedPrice = price
      .replace(new RegExp('\\' + currencySymbol, 'g'), '')
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
      .trim();
    const parsedPrice = parseFloat(formattedPrice);
    return isNaN(parsedPrice) ? 0 : parsedPrice;
  };

  return {
    formatPrice,
    parsePrice,
  };
};

export default usePrice;
