import { NewsletterSubscriptionResponse } from 'types/Newsletter';
import { isTones } from 'utils/brandUtil';
import { apiClient } from '../../helpers/ApiClient';
import { logErrorResponse } from '../../utils/loggerUtil';

const provider = isTones ? 'mailchimp' : 'salesforce';

const fetchSubscriptions = async () => {
  try {
    const { data } = await apiClient<NewsletterSubscriptionResponse>({
      method: 'get',
      url: `/${provider}/newsletter/subscribed`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Newsletter Connector', error, `Error fetching subscriptions`);
    throw error;
  }
};

export type SubscribeToNewsletterParams = {
  email: string;
};

const subscribeToNewsletter = async ({ email }: SubscribeToNewsletterParams) => {
  try {
    const { data } = await apiClient({
      method: 'post',
      url: `/${provider}/newsletter/subscribe/${email}`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Newsletter Connector', error, `Error subscribing ${email} to newsletter`);
    throw error;
  }
};

export { fetchSubscriptions, subscribeToNewsletter };
