import { useMutation, useQueryClient } from '@tanstack/react-query';
import { newsletterKeys } from '.';
import { subscribeToNewsletter } from './connector';

const useSubscribeToNewsletterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: subscribeToNewsletter,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: newsletterKeys.subscriptions() }),
  });
};

export { useSubscribeToNewsletterMutation };
