import { ICON_NAMES } from 'constants/icon';
import { ChangeEvent, ReactNode } from 'react';
import { Field } from 'react-final-form';
import { removeWhiteSpaces } from 'utils/textUtil';
import FormGroup from '../FormGroup/FormGroup';
import { StyledFromGroupResponse } from '../FormGroup/FormGroup.styled';
import FormLabel from '../FormLabel/FormLabel';
import { StyledCheckMarkIcon, StyledCheckbox } from './FormGroupCheckbox.styled';

interface FormGroupCheckboxProps {
  className?: string;
  disabled?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  label?: string | ReactNode;
  name: string;
  required?: boolean;
  showError?: boolean;
  validate?: () => void;
  value?: string;
}

const FormGroupCheckbox = ({
  className,
  disabled,
  handleChange,
  id,
  label,
  name: inputName,
  required,
  showError = true,
  validate,
  value: inputValue,
}: FormGroupCheckboxProps) => (
  <Field component="input" name={inputName} type="checkbox" validate={validate} value={inputValue}>
    {({ input, meta }) => {
      const { checked, name, onBlur, onChange, type, value } = input;
      const { active, error, initial, invalid, submitFailed, valid } = meta;

      const isActive = active ? 'active' : '';
      const isInitial = value && initial ? 'initial' : '';
      const isValid = submitFailed && value && valid ? 'valid' : '';
      const isInvalid = submitFailed && invalid ? 'invalid' : '';

      return (
        <FormGroup className={removeWhiteSpaces(`${className} ${isActive} ${isInitial} ${isInvalid} ${isValid}`)}>
          <StyledCheckbox className={`formGroup-type-${type}`}>
            <input
              checked={checked}
              className="formGroup-input"
              disabled={disabled}
              id={id || name}
              name={name}
              onBlur={() => onBlur()}
              onChange={(event) => {
                onChange(event);
                if (handleChange) {
                  handleChange(event);
                }
              }}
              required={required}
              type={type}
              value={value}
            />

            {label && (
              <FormLabel htmlFor={id || name} withExtraStyling={false}>
                {label}
              </FormLabel>
            )}

            {checked && (
              <StyledCheckMarkIcon
                type="custom"
                className="formGroup-checkMark"
                color="primary-400"
                name={ICON_NAMES.CHECK}
                size={75}
              />
            )}
          </StyledCheckbox>
          {submitFailed && error && showError && (
            <StyledFromGroupResponse className="formGroup-response">{error}</StyledFromGroupResponse>
          )}
        </FormGroup>
      );
    }}
  </Field>
);

export default FormGroupCheckbox;
