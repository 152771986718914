import Icon from 'components/Icon/Icon';
import styled from 'styled-components';
import { color, fontSize, spacer, variable } from 'theme';

export const StyledCheckbox = styled.div`
  display: flex;

  [type='checkbox'] {
    display: none;

    &:checked + label::after {
      border-radius: 3px;
      display: block;
    }
  }

  [type='checkbox'] + label {
    cursor: pointer;
    display: inline-block;
    font-size: ${fontSize(100)};
    margin-right: ${spacer(100)};
    padding-left: ${spacer(200)};
    position: relative;

    &::before {
      background-color: ${color('white')};
      ${variable('border')};
      border-color: ${color('whisper-300')};
      border-radius: 5px;
      content: '';
      display: block;
      height: 20px;
      left: 0;
      margin-right: ${spacer(100)};
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;

      .invalid & {
        background-color: ${color('love-100a')};
        border-color: ${color('love-300')};
      }
    }
  }
`;

export const StyledCheckMarkIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
`;
