// @ts-nocheck Temporary ts-nocheck until we can fix the <FormattedMessage/> instances
import { FormattedMessage } from 'react-intl';
import { getValidationSchema } from 'utils/formUtil';
import * as yup from 'yup';
import FormValidationSettings from '../../constants/formValidation';
import { booleanValidation } from '../../utils/validation';

const { email } = FormValidationSettings.validation;

// FIXME: formattedmessage
const NewsletterValidation = {
  email: yup
    .string()
    .email(<FormattedMessage id="form_validation_email" />)
    .max(email.length.max, (obj) => <FormattedMessage id="form_validation_max" values={{ max: obj.max.toString() }} />)
    .required(<FormattedMessage id="form_validation_required" />),
  terms_and_conditions: booleanValidation(),
};

const NewsletterValidationSchema = getValidationSchema(NewsletterValidation);

export type NewsletterValidationSchemaType = yup.InferType<typeof NewsletterValidationSchema>;

export default NewsletterValidationSchema;
