import CMSSlot from 'components/CMS/CMSSlot/CMSSlot';
import Loader from 'components/Layout/Loader/Loader';
import Modal from 'components/Layout/Modal/Modal';
import Typography from 'components/Typography/Typography';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import StaticPageTranslations from 'i18n/staticPages';
import { MouseEventHandler, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCMSPage, getCmsPageFromState } from 'redux/modules/cms';
import FormGroupCheckbox from '../FormGroupCheckbox/FormGroupCheckbox';

const CMS_PAGE_QUALIFIER = 'privacyPolicy';

const FORM_FIELDS = { TERMS_AND_CONDITIONS: 'terms_and_conditions' };

interface PrivacyCheckBoxProps {
  formId: string;
  formType: string;
  termsAndConditions: string;
  termsAndConditionsLink: string;
}

const PrivacyCheckBox = ({ formId, formType, termsAndConditions, termsAndConditionsLink }: PrivacyCheckBoxProps) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { locale } = router;
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const qualifier = StaticPageTranslations[CMS_PAGE_QUALIFIER][locale];

  const cmsPage = useSelector((state) => getCmsPageFromState(state, qualifier, undefined, undefined));

  const handlePrivacyPolicyClick: MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();

    if (!cmsPage) {
      setIsLoading(true);

      try {
        await dispatch(getCMSPage(qualifier, locale, false, false));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        return;
      }
    }

    setShowPrivacyPolicyModal(true);
  };

  const privacyLabel = (
    <>
      {termsAndConditions}{' '}
      <a onClick={handlePrivacyPolicyClick} role="button" tabIndex={0}>
        <Typography textDecoration="underline">{termsAndConditionsLink}</Typography>
      </a>
    </>
  );

  return (
    <>
      <Modal closeModal={() => setShowPrivacyPolicyModal(false)} show={showPrivacyPolicyModal}>
        <Loader show={isLoading} />
        {cmsPage?.slots?.map((slot, index) => <CMSSlot key={slot.slotId} cmsPage={cmsPage} position={index + 1} />)}
      </Modal>

      <FormGroupCheckbox
        id={`${formType}_${formId}_${FORM_FIELDS.TERMS_AND_CONDITIONS}`}
        label={privacyLabel}
        name={FORM_FIELDS.TERMS_AND_CONDITIONS}
      />
    </>
  );
};

export default PrivacyCheckBox;
